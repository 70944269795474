@import 'ui/variables.module.scss';

.tag {
  background-color: $lightBlue;
  color: $primaryHighlight;
  border-radius: 22px;
  padding: 4px 16px;
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
}