@import 'business/App.scss';

.content {
  @include desktop-max-width;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 0 40px 0;
  @include mobile {
    padding: 0 0 20px 0;
  }

  &.connected {
    padding: 0 40px 40px 40px;
  }
}

.layout {
  min-height: 100vh;
  background-color: $white;
}

.footer {
  @include mobile {
    padding: 20px;
  }
}
