.notShownCountryWrapper {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  .notShownCountry {
    padding: 1px 5px;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
  }
}
