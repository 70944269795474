@import 'ui/variables.module.scss';

.buttons {
  padding-top: 10px;

  & > *:not(:last-child) {
    margin-bottom: 4px;
    padding: 4px 15px;
  }
}

.filterButton {
  background-color: $primaryHighlight;
  border-color: $primaryHighlight;
}
