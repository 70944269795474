@import 'ui/variables.module.scss';

.logoContainer {
    position: relative;
    width: 85%;
    max-width: 1000px;
    margin-bottom: 150px;
}

.logoFlex{
    flex: 1
}
@media screen and (max-width: 992px) {
    .searchBar {
        font-size: 10px;
    }
  }

.searchBar{
    position: absolute;
    width: 75%;
    max-height: 40px;
    height: 15%;
    left: 12%;
    top: 35%;
    background: #FFFFFF;
    box-shadow: 3px 4px 13px 2px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    z-index: 10;
    border: 1px solid $primaryHighlight;
}

.homeLogo{
    height: 100%;
    width: 100%;
}

.searchIcon{
    color: $primaryHighlight
}