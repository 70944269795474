.form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    height: 0;
    flex: 1;
    overflow: scroll;
    padding-bottom: 10px;
  }

  .closeIconContainer {
    height: 20px;

    .closeIcon {
      font-size: 24px;
    }
  }

  .buttons {
    padding-top: 10px;
  }
}
