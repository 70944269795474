@import 'business/App.scss';
@import 'ui/variables.module.scss';

.header {
  background: #ffffff;
  height: 100px !important;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px !important;
  @include mobile {
    padding: 0 20px !important;
  }

  padding-inline: 0;
  line-height: unset !important;
}

.headerContent {
  width: 100%;
  display: flex;
  height: 64px;
  flex-direction: row;
  align-items: center;
}

.headerLeft {
  display: flex;
  align-items: center;
  margin: 0 20px;
  @include mobile {
    margin: 0px;
  }
}

.navLinks {
  @include mobile {
    margin-left: 0px;
  }
  display: flex;
  flex: auto;
  min-width: 0;
}

.filler {
  flex: 1;
}

.userIcon {
  color: white;
  background-color: $blue10;
  border-radius: 50%;
  font-size: 30px;
  width: 35px;
  height: 35px;
}

.menuSection {
  font-size: 18px;
}

.userName {
  @include mobile {
    display: none !important;
  }
}

.menu {
  border: none !important;
  flex: auto;
  min-width: 0;
}

.menuItem {
  border: none !important;
  padding: 0 20px !important;
  font-size: 18px !important;

  &::after {
    border-bottom: none !important;
  }
  &::after:hover {
    border-bottom: none !important;
  }
}

.logoutText {
  color: $red;
  font-size: 18px !important;
  border: none !important;
  padding: 0 20px !important;

  &::after {
    border-bottom: none !important;
  }
  &::after:hover {
    border-bottom: none !important;
  }
}
