@import 'ui/variables.module.scss';

#root {
  width: 100%;
}

html,
body {
  font-family: $primaryFont;
  color: $textColor;
  margin: 0;
}

.separator {
  height: 16px;
}

.margin-auto {
  margin: auto;
}

//
// EFFECTS
//
.elevation1 {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.elevation2 {
  @include elevation2;
}

.innerShadow1 {
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.smallMargin {
  margin: 10px;
}

//
// TYPOS
//

.semibold2432 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.regular1422 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.regular1624 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.regular3846 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 46px;
}

//
// COLOR CLASSES
//
.black {
  color: $black;
}

.white {
  color: $white;
}

.bgWhite {
  background-color: $white;
}

.blue9 {
  color: $blue9;
}

.bgBlue9 {
  background-color: $blue9;
}

.blue5 {
  color: $blue5;
}

.bgBlue5 {
  background-color: $blue5;
}

.grey1 {
  color: $grey1;
}

.bgGrey1 {
  background-color: $grey1;
}

.grey6 {
  color: $grey6;
}

.grey8 {
  color: $grey8;
}

.bgGrey6 {
  background-color: $grey6;
}

//
// CUSTOM STYLES
//
.menuLink {
  color: $black;
  height: fit-content;
}

.headerTitle {
  font-style: normal;
  font-weight: 400;
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  font-family: $primaryFont;
}

.subTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $primaryHighlight;
  margin-bottom: 15px;
}

.tableTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 15px;
}

.headerContainer {
  gap: 10px;
}

.tableLabel {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $black1;
  margin: 0;
}

.card {
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: $black1;
    margin-top: 0;
  }
}

.smallMarginTop {
  margin-top: 40px;
}
.smallMarginBottom15 {
  margin-top: 15px;
}

.ant-table-cell {
  text-align: left !important; // Use of important here to prevent specifying "align: right" for every single column of every table
}

.noStyle {
  margin: 0;
}

.map {
  width: 80%;
}

.controls {
  margin: 10px;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.smallMarginRight {
  margin-right: 5px;
}

.pointerCursor {
  cursor: pointer !important;
}

.configLink {
  margin: 5px;
  cursor: pointer;
  text-decoration: none;
  color: $primaryHighlight;
  span {
    margin-right: 10px;
  }
}

.ant-btn-link {
  color: $primaryHighlight;
  text-decoration: none;
  font-size: $fontSize;
  padding: 2px;
}

.titleRedirect {
  color: $primaryHighlight;
}

.searchBar {
  input {
    background-color: $lightBlue;
  }
}

.hidden {
  display: none;
}

.noShrink {
  flex-shrink: 0;
}

.flexDisplay {
  display: flex;
}

.flex1 {
  flex: 1;
}

.flex-end {
  align-self: flex-end;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-vertical .ant-form-item-label {
  padding: 8px 0 0 0;
}

.ant-table-content table {
  table-layout: fixed !important;
}

.ant-table-tbody > tr > td {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.ant-table-thead > tr > th {
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.ant-table-column-sorter {
  margin: 0px !important;
}

.ant-table-column-title {
  margin: 0px !important;
}
.ant-descriptions {
  width: 100%;
}

.ant-carousel,
.ant-carousel > * {
  height: 100%;
  overflow: hidden;

  .slick-slider {
    height: 100%;

    .slick-track {
      height: 100%;

      .slick-slide div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .slick-list,
  .slick-list > * {
    height: 100%;
  }
}

.ant-image {
  height: 100%;

  img {
    height: 100%;
  }
}

.iconFontSize {
  font-size: 18px;
}

.whiteSpaceNoBreak {
  white-space: nowrap;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 10px !important;
}

.descriptionCards {
  flex-wrap: wrap;
  align-items: stretch;
}

.descriptionCard {
  margin-right: 50px;
  width: 615px;
  border: 1px solid $primaryHighlight;
}

.secondaryButton {
  .ant-btn {
    background-color: $primaryHighlight;
    border-color: $primaryHighlight;
  }
}

.ant-tag {
  font-size: 16px !important;
  padding: 5px !important;
}

.detailResourceContainer {
  background: linear-gradient(
    288.71deg,
    rgba(0, 65, 180, 0.2) 0%,
    rgba(140, 197, 203, 0.2) 52.01%
  );
  border-radius: 24px;
  padding: 20px;
}

.backgroundWhite {
  background-color: white;
}

.colorWhite {
  color: white;
}

.overrideMargin0 {
  margin: 0px !important;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.margin10 {
  margin: 10px;
}
.padding10 {
  padding: 10px;
}

.padding5 {
  padding: 5px;
}

.marginBottom50 {
  margin-bottom: 50px;
}
.textAlignCenter {
  text-align: center;
}

h1 {
  margin-top: 0;
  font-weight: 500;
}

.ant-descriptions-item-label::after {
  content: ' ' !important;
}


.searchButton {
  background-color: $primaryHighlight;
  border-color: $primaryHighlight;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
  flex: 1;
  min-height: 500px;
}

.tabsContainer {
  height: 100%;
  min-height: 500px;
}

.carousel {
  min-width: 50%;
  max-width: 100%;

  min-height: 150px;
  max-height: 200px;
}
