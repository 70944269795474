@import 'ui/variables.module.scss';

.titleAdvancedSearch {
  color: $blue10;
}

.searchBar {
  width: 546px;
  margin-bottom: 50px;
  border-radius: 10px;
}
