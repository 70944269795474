.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
  flex: 1;
  min-height: 500px;
}

.tabsContainer {
  height: 100%;
  min-height: 500px;
}
