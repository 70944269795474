.arrowContainer{
    position: relative;
    height: 100%;
}

.arrowLegendContainer{
    min-width: 200px;
}
.arrow {
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center center; 
    position: absolute;
    top: 50%;
}

.left{
    z-index: 2;
    left: 100%;
    background-color: white;
}

.right{
    z-index: 3;
    left: 0%;
}


.arrowTitle {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    text-align: center;
}
.arrowTitleContainer{
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    width:100%
}