@import 'ui/variables.module.scss';

.familyLink {
  * {
    color: $primaryHighlight !important;
    &:hover {
      cursor: pointer;
    }
  }
}

.container {
  gap: 20px;
}
